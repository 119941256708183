export const BE_COLUMNS_FILTER = []
export const STORAGE_DOMAIN = 'storage.cloud.google.com'
// const SORT_NAME_COLUMN = ['full_name']
export const FORM_STATUS = 2
export const PASSPORT_CHECKED = 'checked'
export const PASSPORT_UNCHECKED = 'unchecked'

export const DEFAULT_SORT = { prop: 'reg_date_formatted', order: 'descending' }

export const SEARCH_MODEL = {
  full_name: '',
  phone: '',
  email: '',
  gender: '',
  division_id: '',
  got_in_date: '',
  status_id: '',
  passport: '',
  round_id: '',
  reg_date: ''
}

export const RULES_FIELDS = {
  full_name: 19,
  phone: 12,
  email: 27,
  division: 18,
  round: 9,
  billing_info: 6,
  status: 13
}

export const SORTING_METHODS = {
  default_sort: (prop) => {
    return (a, b) => {
      const stringA = a[prop].toString()
      const stringB = b[prop].toString()

      if (stringA < stringB) {
        return -1
      }
      if (stringA > stringB) {
        return 1
      }

      return 0
    }
  },
  full_name: (a,b) => {
    return ('' + a.full_name).localeCompare(b.full_name);
  },
  billing_info: (a, b) => {
    const balanceA = a.billing_info?.Balance || 0
    const balanceB = b.billing_info?.Balance || 0
    return balanceA - balanceB
  },
  bank_num: (a, b) => {
    return a.bank_num - b.bank_num
  },
  reg_date_formatted: (fieldA,fieldB) => {
    const dateA = fieldA.reg_date ? new Date(fieldA.reg_date).getTime() : 0
    const dateB = fieldB.reg_date ? new Date(fieldB.reg_date).getTime() : 0
    return dateA - dateB
  },
  passport: (a, b) => {
    const aHasPassport = a.has_passport
    const bHasPassport = b.has_passport
    return aHasPassport - bHasPassport
  }
}

export const getBaseColumns = (statuses, divisionList, cities, rounds) => {
  return [
    {
      prop: 'full_name',
      label: 'Full Name',
      icon: 'vc-icon-users',
      sortable: 'custom',
      searchable: true
    },
    {
      prop: 'phone',
      label: 'Phone',
      icon: 'vc-icon-phone',
      minWidth: '125px',
      sortable: false,
      searchable: true
    },
    {
      prop: 'email',
      label: 'Email',
      icon: 'vc-icon-mail',
      minWidth: '220px',
      sortable: false,
      searchable: true
    },
    {
      prop: 'division',
      label: 'Division',
      icon: 'vc-icon-puzzle',
      minWidth: '168px',
      sortable: false,
      searchable: false,
      isMultipleSelect: true,
      filter: divisionList,
      filterKey: 'division_id'
    },
    {
      prop: 'bank_num',
      label: 'Bank Num',
      icon: 'vc-icon-money',
      sortable: 'custom',
      searchable: false,
    },
    {
      prop: 'round',
      label: 'Round',
      icon: 'vc-icon-puzzle',
      width: '155px',
      sortable: false,
      searchable: false,
      isMultipleSelect: true,
      filter: rounds,
      filterKey: 'round_id'
    },
    {
      prop: 'billing_info',
      label: 'Balance',
      icon: 'vc-icon-billing',
      width: '125px',
      sortable: 'custom',
      searchable: false,
    },
    {
      prop: 'passport',
      label: 'Passport',
      icon: 'vc-icon-checkmark',
      filterKey: 'passport',
      width: '128px',
      sortable: 'custom',
      searchable: false,
      filter: [
        {label: 'Checked', value: PASSPORT_CHECKED},
        {label: 'Unchecked', value: PASSPORT_UNCHECKED}
      ]
    },
    {
      prop: 'gender',
      label: 'Gender',
      icon: 'vc-icon-users',
      sortable: 'custom',
      searchable: false,
      filterKey: 'gender',
      width: '115px',
      filter: [
        { label: 'M', value: 'm' },
        { label: 'F', value: 'f' }
      ]
    },
    {
      prop: 'status',
      label: 'Status',
      icon: 'vc-icon-checkmark',
      filterKey: 'status_id',
      width: '130px',
      sortable: false,
      searchable: false,
      filter: statuses
    },
    {
      prop: 'city_name',
      label: 'City',
      icon: 'vc-icon-city',
      filterKey: 'city_id',
      width: '150px',
      sortable: 'custom',
      searchable: false,
      filter: cities
    },
    {
      prop: 'reg_date_formatted',
      label: 'Got Date',
      icon: 'vc-icon-timer',
      filterKey: 'reg_date',
      width: '130px',
      sortable: 'custom',
      searchable: false,
      datetime: true,
      dateFormat: 'DD-MM-YY'
    }
  ]
}
